@use '../../styles/abstracts/variables' as v;

.home {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden; // Prevent double scrollbars

  .main-header {
    flex-shrink: 0; // Prevent header from shrinking
  }

  .mainContainer {
    flex: 1;
    min-height: 0; // Allow container to shrink
    display: flex;
    flex-direction: column;
  }

  .contentContainer {
    flex: 1;
    min-height: 0; // Allow container to shrink
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: margin-right 0.3s ease-in-out;
    margin-right: 0;

    &.menu-visible {
      margin-right: 260px;
    }

    .scrollableContent {
      flex: 1;
      overflow-y: auto;
    }
  }
}
