@use '../abstracts/variables' as v;

html,
body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  background: var(--bg-secondary);
}

#root,
.app-wrapper {
  height: 100vh;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  overflow: hidden;
}

.mallContainer {
  height: 100%;
  width: calc(100% - #{v.get-component('sidebar', 'width') + v.get-spacing('base', 'sm')});
  padding: v.get-spacing('base', 'lg');
  border-radius: v.get-radius('xl') 0 0 0;
  background: var(--bg-secondary);
}

.mainContainer {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  background-color: var(--bg-secondary);
}

.homeContainer {
  height: 100%;
  padding-top: v.get-spacing('base', 'lg');
  background-color: var(--bg-secondary);
} 