@use '../../../../styles/abstracts/variables' as v;
@use '../../../../styles/abstracts/mixins' as m;
@use '../shared/variables' as shared;

.TableHeader {
  position: sticky;
  top: 0;
  z-index: v.get-z-index('header');
  width: 100%;
  
  // Grid container
  > div {
    display: grid;
    grid-template-columns: 24px var(--column-width) 1fr;
    width: 100%;
  }

  &__spacer-left {
    grid-column: 1;
  }

  &__spacer {
    grid-column: 2;
  }

  &__columns {
    grid-column: 3;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: var(--column-width);
  }

  &__edit-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--column-width);
    padding: 8px;
  }

  &__edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: 8px;
    border: none;
    border-radius: 6px;
    background: transparent;
    color: #6b7280;
    cursor: pointer;
    transition: map-get(shared.$transitions, 'standard');

    &:hover {
      background: var(--bg-primary);
      color: var(--text-primary);
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  // Column styles
  &__column {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: map-get(shared.$header-dimensions, 'column-width');
    width: map-get(shared.$header-dimensions, 'column-width');
    height: map-get(shared.$header-dimensions, 'display-height');
    border-radius: map-get(shared.$header-dimensions, 'border-radius');
    background: transparent;
    transition: map-get(shared.$transitions, 'standard');
    
    &__name {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: map-get(shared.$header-dimensions, 'margin');
      
      span {
        text-align: center;
        // Allow text to wrap onto two lines
        white-space: normal;
        line-height: 1.3;
        // Ensure text doesn't exceed two lines
        display: -webkit-box;
        display: -moz-box;
        display: box;
        -webkit-line-clamp: 2;
        -moz-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        box-orient: vertical;
        overflow: hidden;
        word-break: break-word;
      }
    }
    
    // Edit mode styles
    &--edit {
      cursor: grab;
      
      &:hover {
        background: var(--bg-primary);
        border-color: var(--border-hover-color);
      }
    }

    // Hide button
    &__hide {
      position: absolute;
      left: map-get(shared.$header-dimensions, 'margin');
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      cursor: pointer;
      opacity: 0;
      transition: map-get(shared.$transitions, 'standard');

      svg {
        width: 16px;
        height: 16px;
        color: var(--text-error);
      }
    }

    &:hover &__hide {
      opacity: 1;
    }
  }
}

