@use '../abstracts/colors' as c;
@use '../abstracts/variables' as v;

// Global input autofill normalizations
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-primary);
  -webkit-box-shadow: 0 0 0px 1000px var(--bg-primary) inset;
  transition: background-color 5000s ease-in-out 0s;
  font: inherit;
  font-size: v.fib(3) !important;
}

input:-webkit-autofill-selected {
  font-size: v.fib(3) !important;
}

@-moz-document url-prefix() {
  input {
    font: inherit !important;
    font-size: v.fib(3) !important;
  }
}

.EditForm {
  width: 100%;
  padding: v.get-spacing('base', 'md');

  &__buttons {
    margin-top: v.get-spacing('base', 'md');
    display: flex;
    justify-content: space-between;

    & > * {
      width: v.fib(8) !important;
    }
  }
} 