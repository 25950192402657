@use '../../../../styles/abstracts/variables' as v;
@use '../../../../styles/abstracts/mixins' as m;

// Access table variables using the get-component function
$table: map-get(v.$components, 'table');

.TableMallComponent {
  display: grid;
  grid-template-columns: 24px var(--column-width) 1fr;
  gap: 0;
  width: 100%;
  min-height: 0;
  overflow: auto;
  position: relative;

  &__header {
    display: contents;
  }

  .triangle-wrapper {
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 100%;
    cursor: pointer;

    .triangle {
      width: 20px;
      height: 20px;
      transition: transform 0.2s ease;
      color: var(--text-primary);
      transform-origin: center;

      path {
        stroke-width: 2px;
      }
    }
  }

  &__mallName {
    grid-column: 2;
    display: flex;
    align-items: center;
    height: map-get($table, 'cell-height');
    min-height: map-get($table, 'cell-height');
    width: map-get($table, 'column-width');
    min-width: map-get($table, 'column-width');
    padding: 0 8px;
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--border-color);

    .content-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      flex: 1;
    }

    .mallName {
      font-weight: 500;
      color: var(--color-text-primary);
      @include m.text-truncate;
    }

    .hidden {
      font-weight: 500;
      color: var(--color-text-disabled);
    }
  }

  &__content {
    grid-column: 2 / -1;
    display: contents;
  }

  &__stores {
    grid-column: 2;
    display: flex;
    flex-direction: column;
  }

  &__storeName {
    display: flex;
    align-items: center;
    height: map-get($table, 'cell-height');
    min-height: map-get($table, 'cell-height');
    width: map-get($table, 'column-width');
    min-width: map-get($table, 'column-width');
    gap: 8px;
    padding: 0 8px;
    font-size: map-get(map-get($table, 'font-size'), 'base');
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--border-color);

    span {
      color: var(--color-text-primary);
      @include m.text-truncate;

      &.hidden {
        color: var(--color-text-disabled);
      }
    }
  }

  &__data {
    grid-column: 3;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: map-get($table, 'column-width');
    gap: 0;
    background: transparent;
  }

  &__column {
    display: flex;
    flex-direction: column;
    background: transparent;
  }

  &__cell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: map-get($table, 'cell-height');
    min-height: map-get($table, 'cell-height');
    width: map-get($table, 'column-width');
    min-width: map-get($table, 'column-width');
    padding: 0 16px;
    font-size: map-get(map-get($table, 'font-size'), 'base');
    color: var(--color-text-primary);
    border: none;
    border-bottom: 1px solid var(--border-color);

    &.hidden {
      color: var(--color-text-disabled);
    }
  }
}
