@use '../abstracts/variables' as v;

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--background-color);
  overflow: hidden;
}

.incomeSortingContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  gap: 4px;

  .sortingCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 32px 60px 0 60px;
    position: relative;

    .center-content {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-items: start;
      width: 100%;
      gap: 24px;

      .page-navigation {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-self: start;
        height: 30px;

        .page-indicator {
          background-color: var(--background-color);
          padding: 0 12px;
          border-radius: 4px;
          font-size: 12px;
          color: var(--text-primary);
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          min-width: 100px;
          text-align: center;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          
          span {
            line-height: 30px;
          }
        }

        .yearButton {
          background-color: transparent;
          border: none;
          cursor: pointer;
          color: var(--text-primary);
          font-size: 14px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          z-index: 2;

          svg {
            display: inline-block;
            line-height: 1;
            width: 1em;
            height: 1em;
          }

          &:hover:not(:disabled) {
            color: var(--success-color);
          }

          &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }

      .date-selection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        justify-self: center;
      }
    }

    .nav-arrow {
      background: none;
      border: none;
      color: var(--text-secondary);
      cursor: pointer;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      &:hover {
        color: var(--success-color);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .sortingButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    position: relative;

    .nav-arrow {
      background: none;
      border: none;
      color: var(--text-secondary);
      cursor: pointer;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      &:hover {
        color: var(--success-color);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .sortingLeft, .sortingRight {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }

  .backButton {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border: none;
    background: none;
    color: var(--text-primary);
    cursor: pointer;
    font-size: 14px;
    
    &:hover {
      opacity: 0.7;
    }

    i {
      margin-right: 8px;
    }
  }
}

.scrollableContent {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
} 