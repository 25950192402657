@use '../../../../../../../styles/abstracts/variables' as v;
@use '../../../shared/tabStyles.scss';

.tab-content {
  display: inline-flex;
  align-items: center;
  gap: 6px;

  .tab-icon {
    width: 14px;
    height: 14px;
    color: currentColor;
    flex-shrink: 0;
  }

  .tab-text {
    font-size: 13px;
  }
}

.editor-header__tab {
  &:not(.--active) {
    opacity: 0.6;
  }

  &.--active {
    opacity: 1;
    font-weight: 500;
  }

  &:hover {
    opacity: 0.8;
  }
}