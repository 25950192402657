.income-insights {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 8px 32px;
  min-height: min-content;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .insights-row {
    display: flex;
    gap: 16px;
    width: 100%;
    min-height: fit-content;

    &.main-charts {
      flex: 0 0 550px;
    }

    &.weekly-chart {
      flex: 0 0 450px;
    }

    &.monthly-chart {
      margin-bottom: 32px;
    }
  }

  .insight-section {
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .chart-container {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      gap: auto;
      height: 100%;
      position: relative;

      .recharts-wrapper {
        width: 100% !important;
        height: 100% !important;
        min-height: 300px;
      }

      .chart-header {
        position: relative;
        width: 100%;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;

        .chart-title {
          color: var(--text-secondary);
          font-size: 13px;
          font-weight: 500;
        }

        .info-icon {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 11px;
          color: var(--text-secondary);
          border: 1px solid currentColor;
          cursor: pointer;
          background: var(--bg-primary);
          transition: all 0.2s ease;
          position: relative;

          &:hover {
            color: var(--text-primary);

            &:before {
              content: attr(data-tooltip);
              position: absolute;
              right: calc(100% + 8px);
              top: 50%;
              transform: translateY(-50%);
              background: var(--bg-primary);
              color: var(--text-primary);
              padding: 4px 8px;
              border-radius: 4px;
              font-size: 12px;
              white-space: nowrap;
              border: 1px solid var(--border-color);
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              z-index: 1000;
            }
          }
        }
      }
    }
  }

  .summary-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    padding: 16px 150px 0 100px;

    .summary-item {
      width: fit-content;
      display: flex;
      flex-direction: column;
      gap: 8px;

      label {
        width: fit-content;
        color: var(--text-secondary);
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 6px;

        .info-icon {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          color: var(--text-secondary);
          border: 1px solid currentColor;
          cursor: pointer;
          background: var(--bg-primary);
          transition: all 0.2s ease;
          position: relative;
          margin-top: -1px;

          &:hover {
            color: var(--text-primary);

            &:before {
              content: attr(data-tooltip);
              position: absolute;
              left: calc(100% + 8px);
              top: 50%;
              transform: translateY(-50%);
              background: var(--bg-primary);
              color: var(--text-primary);
              padding: 4px 8px;
              border-radius: 4px;
              font-size: 12px;
              white-space: nowrap;
              border: 1px solid var(--border-color);
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
              z-index: 1000;
            }
          }
        }
      }

      .values-container {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .year-comparison {
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(--text-secondary);
          font-size: 14px;

          .year-label {
            min-width: 45px;
          }

          .value {
            color: var(--chart-previous-year);
            font-weight: 500;

            &.included {
              color: var(--chart-current-year);
            }
          }

          .days-info {
            color: var(--text-tertiary);
            font-size: 12px;
          }

          .change-indicator {
            color: var(--text-secondary);
            font-size: 12px;
            margin-left: 4px;
          }
        }
      }

      .no-data {
        color: var(--text-secondary);
        font-size: 14px;
      }
    }
  }
} 