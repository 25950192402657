@use '../../../../../styles/abstracts/variables' as v;

.column-editor {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 2vh 0;
  }

  &__wrapper {
    width: 100%;
    max-width: 1200px;
    min-height: 600px;
    max-height: 80vh;
    background: var(--bg-primary);
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    animation: slideDown 0.2s ease-out;
  }

  &__content {
    flex: 1;
    overflow: auto;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
