@use '../../../../../../../styles/abstracts/variables' as v;

.editing-area {
  padding: 24px;
  background: var(--bg-primary);
  border-radius: 8px;
  border: 1px solid var(--border-color);

  &__add {
    text-align: center;
    max-width: 480px;
    margin: 0 auto;

    h2 {
      font-size: 18px;
      font-weight: 600;
      color: var(--text-primary);
      margin-bottom: 8px;
    }

    p {
      color: var(--text-secondary);
      font-size: 14px;
      margin-bottom: 24px;
    }
  }

  &__options {
    display: grid;
    gap: 12px;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    background: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: var(--bg-secondary);
      transform: translateY(-1px);
      border-color: var(--border-color-hover);
    }

    &:active {
      transform: translateY(0);
    }
  }

  &__option-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 24px;
    background: var(--bg-secondary);
    border-radius: 8px;
  }
} 