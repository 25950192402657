@use '../../../../../../../styles/abstracts/variables' as v;
@use '../../../shared/tabStyles.scss';

.editor-header {
  background: var(--bg-secondary);
  border-bottom: 1px solid var(--border-color);

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 16px;
    height: 42px;
    gap: 16px;
  }

  &__sections {
    display: flex;
    gap: 24px;
    height: 100%;
  }

  &__section-button {
    padding: 8px 0px 10px 0px;
    border: none;
    background: none;
    color: var(--text-secondary);
    font-size: 13px;
    cursor: pointer;
    position: relative;
    transition: all 0.15s ease;

    &:hover {
      color: var(--text-color);
    }
  }

  .editor-header__section-button.--active {
    color: var(--text-color);
    font-weight: 500;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: currentColor;
    }
  }

  &__sub {
    display: flex;
    align-items: stretch;
    padding: 0 16px;
    height: 36px;
    border-top: 1px solid var(--border-color);
    background: var(--bg-secondary);
    position: relative;
    z-index: 1;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__search {
    position: relative;
    width: 180px;

    .search-icon {
      position: absolute;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      color: var(--text-secondary);
      width: 12px;
      height: 12px;
    }
  }

  &__search-input {
    width: 100%;
    padding: 4px 6px 4px 24px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background: var(--bg-primary);
    color: var(--text-color);
    font-size: 12px;
    height: 24px;

    &::placeholder {
      color: var(--text-secondary);
    }

    &:focus {
      outline: none;
      border-color: var(--primary-color);
    }
  }

  &__delete {
    padding: 4px;
    border: none;
    background: none;
    color: var(--text-secondary);
    cursor: pointer;
    transition: all 0.15s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 4px;

    &:hover {
      color: var(--danger-color);
      background: var(--bg-hover);
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.remove-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  border-radius: 4px;
  background: transparent;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: var(--bg-hover);
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.delete-confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.delete-confirm-dialog {
  background: var(--bg-primary);
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 300px;

  span {
    font-size: 14px;
    color: var(--text-primary);
    text-align: center;
  }

  .delete-confirm-actions {
    display: flex;
    justify-content: center;
    gap: 12px;

    button {
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;
      border: 1px solid var(--border-color);

      &.confirm-button {
        background: transparent;
        color: var(--danger-color);
        border-color: var(--danger-color);

        &:hover {
          background: var(--danger-color);
          color: white;
        }
      }

      &.cancel-button {
        background: transparent;
        color: var(--text-secondary);

        &:hover {
          background: var(--bg-hover);
          color: var(--text-primary);
        }
      }
    }
  }
}