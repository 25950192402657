@use '../../../../../../../styles/abstracts/variables' as v;

.presets {
  padding: 24px;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.preset-section {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__title {
    font-size: 14px;
    font-weight: 600;
    color: var(--text-primary);
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.preset-button {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background: var(--bg-primary);
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  color: var(--text-primary);

  &:hover {
    background: var(--bg-secondary);
    border-color: var(--border-hover-color);
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

  &__range {
    font-size: 12px;
    color: var(--text-secondary);
  }
} 