.YearPicker {
  & * {
    border-color: transparent !important;
    box-shadow: none !important;
  }
  &.ant-select-open * {
    color: var(--success-color) !important;
    fill: var(--success-color) !important;
  }
  & .ant-select-selection-item {
    font-size: 12px;
  }
}

.yearSelector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  padding: 0 4px;
  position: relative;

  &, &.yearSelector {
    width: 400px;
  }

  .yearButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--text-primary);
    font-size: 14px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    z-index: 2;
    position: absolute;

    &-left {
      left: 4px;
    }

    &-right {
      right: 4px;
    }

    &:hover:not(:disabled) {
      color: var(--success-color);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .yearsContainerWrapper {
    overflow: hidden;
    width: 400px;
    margin: 0 28px; // Space for buttons
  }

  .yearsContainer {
    display: flex;
    width: 1176px; // All 21 years * 56px
    transform: translateX(calc(var(--offset, 0) * -56px));

    &.animate {
      transition: transform 500ms cubic-bezier(0.25, 0.1, 0.25, 1);
      transform: translateX(calc((var(--offset, 0) * -56px) + (var(--direction, 1) * 168px)));
    }
  }

  .yearOption {
    flex: 0 0 56px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    color: var(--text-primary);
    text-align: center;
    line-height: 30px;

    &:hover:not(:disabled) {
      color: var(--success-color);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.selected {
      color: var(--success-color);
      font-weight: 600;
    }
  }
}

.year-picker {
  display: inline-block;
  margin: 0 10px;
}
