@use 'functions' as f;
@use 'variables' as v;

// Flexbox helpers
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Typography presets
@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Interactive states
@mixin interactive {
  cursor: pointer;
  transition: all map-get(v.$animation, 'fast');

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
}

// Glass effect
@mixin glass-effect {
  background: var(--glass-bg);
  backdrop-filter: blur(f.get-blur(md));
  border: 1px solid var(--glass-border);
  box-shadow: 
    0 f.space(compact, md) f.space(base, md) var(--glass-shadow),
    inset 0 0 0 1px var(--glass-inset);
} 