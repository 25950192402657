// src/pages/ReportingList/style.scss

.reporting-list-pc {
  height: 100%;
  flex-direction: column;

  .listWrapper {
    height: 100%;
  }
}
