@use '../../../../styles/abstracts/variables' as v;
@use '../../../../styles/abstracts/mixins' as m;

// Access table variables using the get-component function
$table: map-get(v.$components, 'table');

.TableTotal {
  display: grid;
  grid-template-columns: 24px var(--column-width) 1fr;
  gap: 0;
  width: 100%;
  min-height: 0;
  overflow: auto;
  position: relative;

  &__total {
    grid-column: 2;
    display: flex;
    align-items: center;
    height: map-get($table, 'cell-height');
    min-height: map-get($table, 'cell-height');
    width: map-get($table, 'column-width');
    min-width: map-get($table, 'column-width');
    padding: 0 8px;
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--border-color);
    font-weight: 600;
  }

  &__data {
    grid-column: 3;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: map-get($table, 'column-width');
    gap: 0;
  }

  &__cell {
    height: map-get($table, 'cell-height');
    min-height: map-get($table, 'cell-height');
    width: map-get($table, 'column-width');
    min-width: map-get($table, 'column-width');
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--border-color);
    font-size: map-get(map-get($table, 'font-size'), 'base');
  }
}
