// Main Colors
$main-yellow: rgba(218, 253, 0, 1);
$main-yellow-055: rgba(218, 253, 0, 0.55);
$main-black: rgba(18, 18, 18, 1);
$header-black: rgba(28, 28, 28, 1);
$grey-black: rgba(20, 20, 20, 1);
$white: rgba(250, 250, 250, 1);

// Opacity Colors
$black-opacity-40: rgba(250, 250, 250, 0.4);
$black-opacity-05: rgba(250, 250, 250, 0.05);

// Text Colors
$text-color: rgba(250, 250, 250, 0.7);
$text-hover: rgba(250, 250, 250, 1);
$icon-color: #797979;

// Input Colors
$input-border: rgba(250, 250, 250, 0.1);
$input-border-focus: rgba(250, 250, 250, 0.2);
$input-text: rgba(250, 250, 250, 0.9);
$input-placeholder: rgba(250, 250, 250, 0.3);
$input-background: rgba(18, 18, 18, 0.9);

// Status Colors
$error-red: rgba(242, 0, 0, 1);
$title-red: rgba(210, 41, 92, 1);
$title-red-059: rgba(210, 41, 92, 0.59);
$title-grey: rgba(255, 255, 255, 0.53);

// Gradients
$linear-red: linear-gradient(239.18deg, rgba(175, 18, 65, 0.23) -111.92%, rgba(175, 18, 65, 0.24) -111.9%, rgba(175, 18, 65, 0) 77.95%);
$linear-yellow: linear-gradient(239.18deg, rgba(218, 253, 0, 0.23) -111.92%, rgba(218, 253, 0, 0) 77.95%);
$list-button-linear-red: linear-gradient(239.18deg, rgba(211, 0, 0, 0.0483), rgba(175, 18, 18, 0.0504), rgba(211, 0, 0, 0));
$list-button-linear-green: linear-gradient(239.18deg, rgba(142, 253, 0, 0.0483), rgba(142, 253, 0, 0));

// Header Colors (moved from component)
$header-bg: rgba(22, 22, 22, 1);
$hover-color: $main-yellow;  // Use existing main-yellow
$border-color: rgba(255, 255, 255, 0.03);

// Icon Colors
$icon-idle: #797979;
$icon-hover: $white;
$icon-selected: $white;

// Glass Effect Colors
$glass-bg: rgba(255, 255, 255, 0.6);
$glass-border: rgba(255, 255, 255, 0.3);
$glass-inset: rgba(255, 255, 255, 0.5);
$glass-hover: rgba(255, 255, 255, 0.08);
$glass-active: rgba(255, 255, 255, 0.9);

// Calendar Colors
$calendar-day-text: rgba(51, 51, 51, 0.9);
$calendar-day-text-muted: rgba(51, 51, 51, 0.6);
$calendar-day-hover: rgba(0, 0, 0, 0.03);
$calendar-day-range: rgba(213, 213, 213, 0.2);
$calendar-separator: rgba(51, 51, 51, 0.1);

// Special Colors
$accent-brown: #997033;  // Used in tabs active state

// Category Colors Map
$category-colors: (
  'food': #FF6B6B,
  'clothing': #4ECDC4,
  'electronics': #45B7D1,
  'beauty': #96CEB4,
  'sports': #FFEEAD,
  'home': #D4A5A5,
  'entertainment': #9B59B6,
  'services': #3498DB,
  'fashion-travel': #E74C3C,
  'food-beverage': #2ECC71,
  'house-home': #F1C40F,
  'other-business': #95A5A6,
  'other-retail': #7F8C8D,
  'serving': #E67E22,
  'special-stores': #1ABC9C
);