@use '../../../../../styles/abstracts/variables' as v;

.editor-header__tabs {
  display: flex;
  gap: 24px;
  width: 100%;
  height: 100%;
  padding: 0 4px;
}

.editor-header__tab {
  padding: 8px 0px 10px 0px;
  border: none;
  background: none;
  color: var(--text-secondary);
  font-size: 13px;
  cursor: pointer;
  position: relative;
  transition: all 0.15s ease;
  white-space: nowrap;

  &:hover {
    color: var(--text-color);
  }
}

.editor-header__tab.--active {
  color: var(--text-color);
  font-weight: 500;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: currentColor;
  }
} 