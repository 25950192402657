.pc-stores-grid-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.pc-stores-grid {
  width: 100%;
  max-width: 900px;
  min-width: 700px;
  padding: 12px;
  font-size: 14px;

  .pc-stores-list {
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
    margin-bottom: 16px;
    background-color: var(--bg-primary);
  }

  .pc-stores-list-header {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr);
    background-color: var(--bg-primary);
    padding: 8px 4px;
    font-size: 13px;
    color: var(--text-secondary);
    border-bottom: 1px solid var(--border-color);

    span {
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;

      &.sortable {
        cursor: pointer;
        user-select: none;
        position: relative;
        transition: color 0.3s ease;

        &:hover {
          color: var(--text-primary);
        }
      }
    }

    span:not(:first-child) {
      justify-content: center;
    }
  }

  .pc-store-option {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr);
    padding: 6px 4px;
    border-bottom: 1px solid var(--border-color);
    transition: background-color 0.2s ease;
    font-size: 13px;
    color: var(--text-primary) !important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: var(--hover-bg);
    }

    &.reported {
      border-left: 2px solid var(--status-success);
    }

    &.not-reported {
      border-left: 2px solid var(--status-error);
    }

    &.inactive {
      opacity: 0.8;
      border-left: none;
    }

    .pc-store-option-name,
    span {
      padding: 0 8px;
      display: flex;
      align-items: center;
      transition: color 0.3s ease;
      min-width: 120px;
      line-height: 24px;
    }

    .pc-store-option-name {
      justify-content: flex-start;
      font-size: 13px;
      color: var(--text-primary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 12px;
    }

    span:not(:first-child) {
      justify-content: center;
    }

    &:hover {
      span, .pc-store-option-name {
        color: var(--text-primary);
      }
    }
  }

  .pc-stores-list-separator {
    height: 24px;
    border-bottom: 1px solid var(--border-color);
  }

  .pc-stores-loading,
  .pc-no-stores {
    padding: 20px;
    text-align: center;
    color: var(--text-secondary);
    font-size: 14px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
