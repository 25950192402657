@use '../../../../../styles/abstracts/variables' as v;

.retail-editor {
  display: flex;
  flex-direction: column;
  height: 600px;
  background: var(--bg-primary);
  border-radius: 8px;
  overflow: hidden;

  &__header {
    border-bottom: 1px solid var(--border-color);
    background: var(--bg-secondary);

    &-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;
      min-height: 42px;
    }

    &-sub {
      display: flex;
      align-items: center;
      padding: 0 12px;
      min-height: 36px;
      border-top: 1px solid var(--border-color);
    }
  }

  &__unified-view {
    display: grid;
    grid-template-columns: 220px 1fr;
    gap: 0;
    flex: 1;
    min-height: 0;
    overflow: hidden;
  }

  &__mall-section {
    grid-column: 1;
    border-right: 1px solid var(--border-color);
    overflow: auto;
    background: var(--bg-secondary);

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  &__right-section {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__stores-section {
    flex: 1 1;
    overflow: auto;
    padding: 0 16px;
    margin-top: 10px;
    background: var(--bg-primary);

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--border-color);
      border-radius: 3px;

      &:hover {
        background: var(--text-secondary);
      }
    }
  }

  .retail-editor__categories {
    display: flex;
    align-items: center;
    gap: 16px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      padding: 8px 4px;
      font-size: 13px;
      color: var(--text-secondary);
      cursor: pointer;
      transition: all 0.15s ease;
      white-space: nowrap;
      display: inline-flex;
      align-items: center;
      user-select: none;
      position: relative;

      &:hover {
        color: var(--text-color);
      }

      &.active {
        color: var(--primary-color);
        font-weight: 500;

        &:after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          height: 2px;
          background: var(--primary-color);
          border-radius: 2px 2px 0 0;
        }

        .categoryIcon {
          color: var(--primary-color);
        }
      }

      .category-content {
        display: inline-flex;
        align-items: center;
        gap: 4px;

        .categoryIcon {
          width: 14px;
          height: 14px;
          color: currentColor;
          flex-shrink: 0;
        }
      }
    }
  }

  &__malls {
    padding: 0;
    margin-top: 10px;
  }

  &__mall-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 0px 4px 0px;
    margin: 0px 16px 0px 16px;
    cursor: pointer;
    transition: all 0.15s ease;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    min-height: 36px;
    color: rgba(0, 0, 0, 0.45);
    border-bottom: 2px solid var(--border-color);

    &:hover {
      color: rgba(0, 0, 0, 0.85);
      background: rgba(0, 0, 0, 0.02);
    }

    .status-circle {
      background: rgba(0, 0, 0, 0.15);
    }

    &.active {
      .status-circle {
        background: #4caf50;
      }
    }

    &.selected {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      border-bottom-color: var(--primary-color);
    }

    &.inactive {
      color: rgba(0, 0, 0, 0.25);
      
      .status-circle {
        background: rgba(0, 0, 0, 0.15);
      }
    }

    &.selected.active {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      border-bottom-color: #4caf50;

      .status-circle {
        background: #4caf50;
      }
    }
  }

  .status-circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    flex-shrink: 0;
    transition: all 0.15s ease;
  }

  .mall-name {
    flex: 1;
    min-width: 0;
    position: relative;

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      line-height: 1.4;
    }
  }

  &__mall-group {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
} 