.months-component {
  width: 100%;
  height: 100%;
  background-color: var(--bg-primary);
  border-radius: 2px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  .months-text {
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    color: var(--text-primary);
    user-select: none;
  }

  &:hover {
    .months-text {
      color: var(--success-color);
    }
  }

  .months-dropdown {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    background-color: var(--bg-primary);
    border-radius: 2px;
    overflow: hidden;
    z-index: 1000;
    box-shadow: 0 2px 8px var(--date-glass-shadow);

    .month-option {
      padding: 8px;
      font-size: 13px;
      color: var(--text-secondary);
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: var(--hover-bg);
        color: var(--success-color);
      }

      &.selected {
        color: var(--success-color);
      }

      &.disabled {
        color: var(--text-secondary);
        opacity: 0.3;
        cursor: not-allowed;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .months-icon {
    margin-left: 4px;
    color: var(--text-primary);
    transition: all 0.2s ease;

    &.open {
      transform: rotate(180deg);
    }

    &:hover {
      color: var(--success-color);
    }
  }
}

.listBorderlessButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 2px 10px 5px 10px;
  color: var(--text-primary);
  font-size: 14px;
  transition: all 0.2s ease;
  position: relative;
  border-bottom: 2px solid transparent;

  &:hover:not(:disabled) {
    color: var(--success-color);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.selected {
    color: var(--success-color);
    font-weight: 600;
    border-bottom: 2px solid var(--success-color);
  }

  &.future {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
