@use '../../../../../../../styles/abstracts/variables' as v;

.retail-editor {
  &__stores {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 6px;
    }
  }

  &__store {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 8px;
    border-radius: 4px;
    background: var(--bg-primary);
    border: 1px solid var(--border-color);
    transition: all 0.15s ease;
    cursor: pointer;

    &:hover {
      background: var(--bg-hover);
      border-color: var(--border-hover);
    }

    &--inactive {
      opacity: 0.5;
    }

    .status-circle {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: var(--text-secondary);
      flex-shrink: 0;

      &.active {
        background: var(--success-color);
      }
    }

    .store-name {
      display: flex;
      align-items: center;
      gap: 6px;
      flex: 1;
      min-width: 0;

      .store-category-icon {
        width: 14px;
        height: 14px;
        color: var(--text-secondary);
        flex-shrink: 0;
      }

      .store-details {
        flex: 1;
        min-width: 0;

        span {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 13px;
          color: var(--text-color);
        }
      }
    }
  }

  &__no-stores {
    grid-column: 1 / -1;
    text-align: center;
    padding: 24px;
    color: var(--text-secondary);
    font-size: 13px;
  }
} 