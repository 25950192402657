@use '../../../styles/abstracts/mixins' as m;

// Base nav button style
%nav-button-base {
  display: flex;
  align-items: center;
  padding: 0 12px;
  text-transform: capitalize;
  border: none;
  background: none;
  color: var(--text-primary);
  cursor: pointer;
  transition: opacity 0.2s ease;
  font-size: 14px;
  

  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Dropdown specific styles
%dropdown-nav-button {
  @extend %nav-button-base;
  gap: 8px;
  position: relative;
  padding-right: 32px;  // Make room for both triangles

  &::after,
  &::before {
    content: '';
    position: absolute;
    right: 12px;
    top: 50%;
    width: 8px;
    height: 4px;
    background: var(--text-primary);
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    transition: transform 0.2s ease;
  }

  &.open {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
    &::before {
      display: none;
    }
  }
}

// Common header section styles
%header-section {
  display: flex;
  align-items: flex-end;
  height: 100%;
  gap: 8px;
  
  .nav-button {
    @extend %nav-button-base;
  }

  .user-dropdown {
    display: flex;
    align-items: flex-end;
    position: relative;
    height: 100%;

    .nav-button {
      @extend %dropdown-nav-button;
    }

    .dropdown-content {
      position: absolute;
      top: calc(100% + 6px);
      right: 0;
      background-color: var(--bg-primary);
      min-width: 180px;
      border-radius: 4px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      z-index: 1000;
      border: 1px solid var(--border-color);
      padding: 4px 0;

      .dropdown-item {
        padding: 8px 16px;
        color: var(--text-primary);
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        font-size: 14px;
        height: 36px;
        cursor: pointer;

        &:hover {
          background-color: var(--hover-bg);
        }

        .value {
          color: var(--text-primary);
          font-size: 13px;
        }
      }
    }
  }
}

.main-header {
  width: 100%;
  background: var(--bg-secondary);
  border-bottom: 1px solid var(--border-color);
  height: 64px;

  .header-wrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    padding-bottom: 6px;
    width: 100%;

    .header-left {
      @extend %header-section;
    }

    .header-controls {
      @extend %header-section;
      justify-content: center;
    }

    .header-actions {
      @extend %header-section;
      justify-self: end;
    }
  }

  .header-nav {
    display: flex;
    align-items: flex-end;
    height: 100%;
    gap: 8px;

    .nav-button {
      @extend %nav-button-base;

      &.selected {
        color: var(--text-primary);
        opacity: 1;
        font-weight: 500;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: -6px;
          left: 12px;
          right: 12px;
          height: 2px;
          background: var(--text-primary);
          border-radius: 1px;
        }
      }

      &:hover:not(.selected) {
        opacity: 0.7;
      }
    }
  }

  .header-title {
    margin-left: 24px;
    font-size: 16px;
    color: var(--text-primary);
  }

  .header-sub {
    height: 40px;
    background-color: var(--bg-secondary);
    border-top: 1px solid var(--border-color);

    .sub-actions {
      display: flex;
      align-items: flex-end;
      height: 100%;
      justify-content: space-between;
      padding: 0 24px;
      padding-top: 6px;
      
      > div {
        display: flex;
        align-items: flex-end;
        gap: 8px;
      }

      .nav-button {
        @extend %nav-button-base;
        height: 100%;
      }
    }
  }
}

.nav-group {
  display: flex;
  align-items: flex-end;
  gap: 8px;

  .nav-separator {
    color: var(--text-secondary);
    font-size: 16px;
  }

  .nav-current {
    color: var(--text-secondary);
    font-size: 14px;
    padding: 0 12px;

    &.selected {
      color: var(--text-primary);
      font-weight: 500;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 12px;
        right: 12px;
        height: 2px;
        background: var(--text-primary);
        border-radius: 1px;
      }
    }
  }
}

