@use '../../../../../../../styles/abstracts/variables' as v;

.calendar {
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: fit-content;
  margin: 0 auto;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &__months {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 48px;
    justify-content: center;
  }

  &__month {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 280px; // 7 * (40px) for days
    height: 360px; // Fixed height to prevent jumping
  }

  &__month-header {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-primary);
    text-align: center;
    text-transform: capitalize;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--border-color);
    height: 44px; // Fixed height for header
  }

  &__weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
    text-align: center;
    padding: 0 4px;
    height: 32px; // Fixed height for weekdays
  }

  &__weekday {
    color: var(--text-secondary);
    font-size: 14px;
    text-transform: lowercase;
    padding: 4px;
    font-weight: 600;
  }

  &__weeks {
    display: grid;
    grid-template-rows: repeat(6, 1fr); // Always show 6 rows
    gap: 4px;
    padding: 0 4px;
    flex: 1;
  }

  &__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;
  }

  &__day {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    border-radius: 6px;
    font-size: 15px;
    color: var(--text-primary);
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    padding: 0;
    margin: 0;

    &:not(:disabled):hover {
      background: var(--bg-secondary-transparent);
    }

    &.--empty {
      cursor: default;
    }

    &.--selected, &.--endpoint {
      background: #e6b800;
      color: black;
      font-weight: 600;
    }

    &.--in-range {
      background: rgba(230, 184, 0, 0.2);
      color: #e6b800;
      font-weight: 500;

      &:hover {
        background: rgba(230, 184, 0, 0.3);
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    border-top: 1px solid var(--border-color);
  }

  &__navigation {
    display: flex;
    gap: 8px;
  }

  &__nav-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    border-radius: 4px;
    color: var(--text-secondary);
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: var(--bg-secondary-transparent);
      color: var(--text-primary);
    }
  }

  &__tabs {
    display: flex;
    gap: 8px;
  }

  &__tab-button {
    padding: 8px 16px;
    border: none;
    background: none;
    border-radius: 4px;
    color: var(--text-secondary);
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 14px;

    &:hover {
      background: var(--bg-secondary-transparent);
      color: var(--text-primary);
    }
  }
} 