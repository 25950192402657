@use '../../../../../../../styles/abstracts/variables' as v;
@use '../../../../../../../styles/abstracts/mixins' as m;
@use '../../../../shared/variables' as shared;

.columns-overview {
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  background: var(--bg-primary);

  &__row {
    display: flex;
    gap: 16px;
    align-items: stretch;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: var(--column-width);
    height: 152.5px;

    &.--hidden {
      .column-display {
        opacity: 0.5;
        background: var(--bg-secondary);
        border: 1px dashed var(--border-color);

        &[data-type="diff"] {
          .column-display__diff {
            color: var(--text-secondary);
          }
        }
      }

      .toggle-button-wrapper {
        opacity: 0.7;
        
        &:hover {
          opacity: 1;
          background: var(--bg-secondary-transparent);
        }
      }
    }

    &-action {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 100%;
    }
  }
}

.column-display {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: map-get(shared.$header-dimensions, 'margin');
  height: 116.5px;
  min-height: 116.5px;
  flex: 1;
  @include m.flex-center;

  &.--selected {
    border-color: var(--border-hover-color);
    background: var(--bg-hover);
  }

  &__button {
    border: none;
    background: none;
    font-size: 14px;
    color: var(--text-primary);
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
    width: 100%;
    padding: map-get(shared.$header-dimensions, 'margin') map-get(shared.$header-dimensions, 'margin') * 2;
  
    &:hover {
      background: var(--bg-hover);
      color: var(--text-primary);
    }

    &.--active {
      background: var(--bg-active);
      color: var(--text-active);
    }
  }

  &__diff {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--text-primary);
    font-size: 14px;
  }

  .text-content {
    @include m.text-truncate;
    width: 100%;
    text-align: center;
  }
}

.add-column-display {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--border-color);
  transition: all 0.2s ease;

  &::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    background: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    font-size: 12px;
    color: var(--text-secondary);
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease;
    z-index: 1000;
  }

  &:hover {
    border-color: var(--border-hover-color);
    background: var(--bg-hover);
    transform: translateY(-1px);

    &::after {
      opacity: 1;
    }

    svg {
      stroke: var(--color-success);
    }
  }

  svg {
    width: 24px;
    height: 24px;
    stroke: var(--text-success);
    stroke-width: 1.2;
    fill: none;
    transition: all 0.2s ease;
  }
}
