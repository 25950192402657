@use '../../../../../../../styles/abstracts/variables' as v;

.vat-mismatch {
  margin-bottom: 16px;
  padding: 12px;
  background: var(--bg-warning-transparent);
  border: 1px solid var(--border-warning);
  border-radius: 6px;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__text {
    color: var(--text-warning);
    font-size: 14px;
  }

  &__button {
    padding: 8px 16px;
    border: 1px solid var(--border-warning);
    border-radius: 4px;
    background: var(--bg-warning-transparent);
    color: var(--text-warning);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: var(--bg-warning);
      color: var(--text-on-warning);
    }

    &:active {
      transform: translateY(1px);
    }
  }
} 