@use '../abstracts/colors' as c;

:root {
  // Light mode colors
  --bg-primary: #ffffff;
  --bg-secondary: #f5f5f5;
  --bg-tertiary: rgba(39, 38, 41, 0.1);
  --bg-quaternary: rgba(34, 34, 37, 0.6);
  --bg-secondary-transparent: rgba(245, 245, 245, 0.95);
  --text-primary: #272727;
  --text-secondary: #878787;
  --border-color: rgba(0, 0, 0, 0.1);
  --hover-bg: rgba(0, 0, 0, 0.05);
  --success-color: rgba(0, 0, 0, 0.9);
  --error-color: rgba(217, 40, 40, 0.8);
  --status-success: rgba(119, 190, 29, 0.8);
  --status-error: rgba(217, 40, 40, 0.8);
  --brutto-color: rgb(131, 136, 255);
  
  // Chart Colors Light
  --chart-current-year: #2196f3;
  --chart-previous-year: #e0e0e0;
  
  // Editor Theme Light
  --bg-hover: rgba(0, 0, 0, 0.05);
  --bg-active: rgba(0, 0, 0, 0.1);
  --text-active: #272727;
  --border-hover-color: rgba(0, 0, 0, 0.2);
  --border-active-color: rgba(0, 0, 0, 0.3);
  --bg-warning: #fff3cd;
  --bg-warning-transparent: rgba(255, 243, 205, 0.2);
  --text-warning: #856404;
  --text-on-warning: #664d03;
  --border-warning: #ffeeba;
  --text-success: #28a745;
  --color-success: #28a745;
  
  // Table Theme Light
  --column-width: 160px;
  --cell-height: 32px;
  --grid-border: 1px solid rgba(0, 0, 0, 0.1);
  --mall-gap: 12px;
  --table-row-bg: rgba(0, 0, 0, 0.03);
  --table-header-bg: rgba(0, 0, 0, 0.05);
  --table-hover-bg: rgba(0, 0, 0, 0.08);
  --table-active-bg: rgba(0, 0, 0, 0.1);
  
  // Icon Theme Light
  --icon-idle: rgba(51, 51, 51, 0.4);
  --icon-hover: rgba(51, 51, 51, 0.7);
  --icon-selected: rgba(51, 51, 51, 0.9);
  --icon-disabled: rgba(51, 51, 51, 0.2);
  --icon-error: rgba(217, 41, 40, 0.7);
  --icon-success: rgba(119, 190, 29, 0.7);
  
  // Icon Opacities Light
  --icon-opacity-full: 1;
  --icon-opacity-medium: 0.72;
  --icon-opacity-low: 0.4;
  --icon-opacity-disabled: 0.2;
  
  // DateSelector Light Theme
  --date-glass-bg: rgba(255, 255, 255, 0.6);
  --date-glass-border: rgba(0, 0, 0, 0.1);
  --date-glass-inset: rgba(255, 255, 255, 0.5);
  --date-preview-bg: rgba(255, 255, 255, 0.8);
  --date-tabs-bg: rgba(0, 0, 0, 0.03);
  --date-tabs-active-bg: #ffffff;
  --date-tabs-active-color: #997033;
  --date-tabs-shadow: rgba(0, 0, 0, 0.05);
  --date-preset-bg: rgba(255, 255, 255, 0.05);
  --date-preset-hover: rgba(255, 255, 255, 0.1);
  --date-preset-border: rgba(255, 255, 255, 0.1);
  --date-day-selected-bg: rgba(255, 255, 255, 0.9);
  --date-day-selected-border: rgba(0, 0, 0, 0.1);
  --date-day-selected-shadow: rgba(0, 0, 0, 0.05);
  
  // DateSelector Light Theme (additional)
  --date-glass-shadow: rgba(0, 0, 0, 0.1);
  --date-preview-shadow: rgba(0, 0, 0, 0.05);
  --date-preview-inset: rgba(255, 255, 255, 0.5);
  --date-day-text: rgba(51, 51, 51, 0.9);
  --date-day-text-muted: rgba(51, 51, 51, 0.6);
  --date-day-hover: rgba(0, 0, 0, 0.03);
  --date-day-hover-text: rgba(51, 51, 51, 0.7);
  --date-day-range: rgba(213, 213, 213, 0.2);
  
  // DateRange Button Light Theme
  --date-range-bg: rgba(255, 255, 255, 0.04);
  --date-range-border: rgba(255, 255, 255, 0.1);
  --date-range-hover-bg: rgba(255, 255, 255, 0.08);
  --date-range-hover-border: rgba(255, 255, 255, 0.2);
  
  --bg-error: rgba(211, 47, 47, 0.1);
  --bg-error-hover: rgba(211, 47, 47, 0.2);
  --text-error: #d32f2f;
  --bg-secondary-hover: rgba(0, 0, 0, 0.05);
  
  // Category Colors
  --category-food: #{map-get(c.$category-colors, 'food')};
  --category-clothing: #{map-get(c.$category-colors, 'clothing')};
  --category-electronics: #{map-get(c.$category-colors, 'electronics')};
  --category-beauty: #{map-get(c.$category-colors, 'beauty')};
  --category-sports: #{map-get(c.$category-colors, 'sports')};
  --category-home: #{map-get(c.$category-colors, 'home')};
  --category-entertainment: #{map-get(c.$category-colors, 'entertainment')};
  --category-services: #{map-get(c.$category-colors, 'services')};
  --category-fashion-travel: #{map-get(c.$category-colors, 'fashion-travel')};
  --category-food-beverage: #{map-get(c.$category-colors, 'food-beverage')};
  --category-house-home: #{map-get(c.$category-colors, 'house-home')};
  --category-other-business: #{map-get(c.$category-colors, 'other-business')};
  --category-other-retail: #{map-get(c.$category-colors, 'other-retail')};
  --category-serving: #{map-get(c.$category-colors, 'serving')};
  --category-special-stores: #{map-get(c.$category-colors, 'special-stores')};
  
  &[data-theme="dark"] {
    --bg-primary: #161616;
    --bg-secondary: #1a1a1a;
    --bg-tertiary: rgba(39, 38, 41, 0.25);
    --bg-quaternary: rgba(34, 34, 37, 0.8);
    --bg-secondary-transparent: rgba(26, 26, 26, 0.95);
    --text-primary: rgba(250, 250, 250, 0.9);
    --text-secondary: rgba(250, 250, 250, 0.7);
    --border-color: rgba(255, 255, 255, 0.03);
    --hover-bg: rgba(255, 255, 255, 0.05);
    --success-color: rgba(255, 255, 255, 0.9);
    --error-color: rgba(217, 40, 40, 0.8);
    --status-success: rgba(119, 190, 29, 0.8);
    --status-error: rgba(217, 40, 40, 0.8);
    --brutto-color: rgb(131, 136, 255);
    
    // Chart Colors Dark
    --chart-current-year: #42a5f5;
    --chart-previous-year: #616161;
    
    // Editor Theme Dark
    --bg-hover: rgba(255, 255, 255, 0.05);
    --bg-active: rgba(255, 255, 255, 0.1);
    --text-active: rgba(250, 250, 250, 0.9);
    --border-hover-color: rgba(255, 255, 255, 0.2);
    --border-active-color: rgba(255, 255, 255, 0.3);
    --bg-warning: #382f16;
    --bg-warning-transparent: rgba(56, 47, 22, 0.2);
    --text-warning: #ffd54f;
    --text-on-warning: #ffd54f;
    --border-warning: #4d421f;
    --text-success: #4caf50;
    --color-success: #4caf50;
    
    // Icon Theme Dark
    --icon-idle: rgba(250, 250, 250, 0.4);
    --icon-hover: rgba(250, 250, 250, 0.7);
    --icon-selected: rgba(250, 250, 250, 0.9);
    --icon-disabled: rgba(250, 250, 250, 0.2);
    --icon-error: rgba(217, 41, 40, 0.7);
    --icon-success: rgba(119, 190, 29, 0.7);
    
    // Icon Opacities Dark (same as light for consistency)
    --icon-opacity-full: 1;
    --icon-opacity-medium: 0.72;
    --icon-opacity-low: 0.4;
    --icon-opacity-disabled: 0.2;
    
    // DateSelector Dark Theme
    --date-glass-bg: rgba(22, 22, 22, 0.6);
    --date-glass-border: rgba(255, 255, 255, 0.1);
    --date-glass-inset: rgba(255, 255, 255, 0.1);
    --date-preview-bg: rgba(26, 26, 26, 0.8);
    --date-tabs-bg: rgba(255, 255, 255, 0.03);
    --date-tabs-active-bg: var(--bg-primary);
    --date-tabs-active-color: var(--hover-color);
    --date-tabs-shadow: rgba(0, 0, 0, 0.1);
    --date-preset-bg: rgba(255, 255, 255, 0.05);
    --date-preset-hover: rgba(255, 255, 255, 0.1);
    --date-preset-border: rgba(255, 255, 255, 0.1);
    --date-day-selected-bg: var(--bg-primary);
    --date-day-selected-border: var(--border-color);
    --date-day-selected-shadow: rgba(0, 0, 0, 0.1);
    
    // DateSelector Dark Theme (additional)
    --date-glass-shadow: rgba(0, 0, 0, 0.2);
    --date-preview-shadow: rgba(0, 0, 0, 0.1);
    --date-preview-inset: rgba(255, 255, 255, 0.1);
    --date-day-text: var(--text-primary);
    --date-day-text-muted: var(--text-secondary);
    --date-day-hover: rgba(255, 255, 255, 0.03);
    --date-day-hover-text: var(--text-secondary);
    --date-day-range: rgba(255, 255, 255, 0.1);
    
    // DateRange Button Dark Theme
    --date-range-bg: rgba(255, 255, 255, 0.04);
    --date-range-border: rgba(255, 255, 255, 0.1);
    --date-range-hover-bg: rgba(255, 255, 255, 0.08);
    --date-range-hover-border: rgba(255, 255, 255, 0.2);
    
    --bg-error: rgba(211, 47, 47, 0.2);
    --bg-error-hover: rgba(211, 47, 47, 0.3);
    --text-error: #ff5252;
    --bg-secondary-hover: rgba(255, 255, 255, 0.1);
    
    // Table Theme Dark
    --grid-border: 1px solid rgba(255, 255, 255, 0.1);
    --table-row-bg: rgba(0, 0, 0, 0.2);
    --table-header-bg: rgba(0, 0, 0, 0.3);
    --table-hover-bg: rgba(255, 255, 255, 0.05);
    --table-active-bg: rgba(255, 255, 255, 0.08);
    
    // Category Colors (same in dark mode)
    --category-food: #{map-get(c.$category-colors, 'food')};
    --category-clothing: #{map-get(c.$category-colors, 'clothing')};
    --category-electronics: #{map-get(c.$category-colors, 'electronics')};
    --category-beauty: #{map-get(c.$category-colors, 'beauty')};
    --category-sports: #{map-get(c.$category-colors, 'sports')};
    --category-home: #{map-get(c.$category-colors, 'home')};
    --category-entertainment: #{map-get(c.$category-colors, 'entertainment')};
    --category-services: #{map-get(c.$category-colors, 'services')};
    --category-fashion-travel: #{map-get(c.$category-colors, 'fashion-travel')};
    --category-food-beverage: #{map-get(c.$category-colors, 'food-beverage')};
    --category-house-home: #{map-get(c.$category-colors, 'house-home')};
    --category-other-business: #{map-get(c.$category-colors, 'other-business')};
    --category-other-retail: #{map-get(c.$category-colors, 'other-retail')};
    --category-serving: #{map-get(c.$category-colors, 'serving')};
    --category-special-stores: #{map-get(c.$category-colors, 'special-stores')};
  }
} 