.editableCalendarInput {
  position: relative;
  margin: 0 8px;

  & > svg {
    position: absolute;
    top: 5.5px;
    left: 5px;
    z-index: 10;
  }

  & > input {
    background: var(--bg-tertiary);
    border-radius: 3px;
    outline: none;
    border: 0.5px solid transparent;
    max-width: 96px;
    width: 100%;
    height: 18px !important;
    min-height: 18px;
    max-height: 18px;
    font-size: 12px;
    text-align: center;
    color: var(--text-secondary);
    
    &:focus {
      border: 0.5px solid var(--border-color);
      height: 18px !important;
      min-height: 18px;
      max-height: 18px;
    }
  }

  &.included {
    & > input {
      color: var(--brutto-color);
    }
  }
}

.IncomeCalendarCell {
  width: max(80px, 14.2857142857%);
  display: flex;
  flex-direction: column;

  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;

  &__day {
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 400;
  }

  .fields-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }
}
