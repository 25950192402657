.storeName {
  font-size: 18px;
  font-weight: 400;
  color: var(--text-secondary);
  padding-right: 8px;
}
.exportAndNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.weekDaysTax {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-secondary);
  text-align: left;
  height: 40px;
  padding-top: 13px;
  width: 100%;
  padding-left: 24px;
}
.weekDaysIncome {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-secondary);
  text-align: left;
  height: 40px;
  padding-top: 13px;
  margin-top: 24px;
  background-color: var(--bg-tertiary);
  width: 100%;
  padding-left: 24px;
}
.weekDays {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-secondary);
  text-align: center;
  width: 100%;
  min-width: 96px;
}
.weekDaysContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.days {
  font-size: 12px;
  font-weight: 600;
  color: var(--text-primary);
  width: 88px;
  text-align: center;
  cursor: pointer;
}
.weekDaysTitle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 57px;
}
.sum {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-primary);
  width: 120px;
  text-align: center;

  &.included {
    color: var(--brutto-color);
  }
}
.incomeHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.incomeInfo {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 0;
  width: 100%;
  height: auto;

  .text {
    color: var(--text-secondary);
    font-size: 12px;
    margin-right: 4px;
  }
  
  .name {
    color: var(--text-primary);
    font-size: 12px;
  }
}

.yearMonthContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.width30 {
  width: 30%;
}
.incomeWrapper {
  border-radius: 16px;
  height: auto;
  min-height: calc(100vh - 300px);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.incomeWeekdaysHeader {
  display: flex;
  align-items: center;
  margin-left: 96px;
  margin-right: 96px;
  padding: 0px 0px 24px 0px;
  border-radius: 16px 16px 0px 0px;
}
.rowsIncome {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-right: 57px;
  margin-top: 24px;
}
.nameWeek {
  width: 10%;
  padding-bottom: 120px;
}
.rowsIncomeWrapper {
  width: 10%;
  display: flex;
  flex-direction: column;
}
.width80 {
  width: 80%;
}
.taxTitle {
  text-align: left;
  height: 40px;
  padding-top: 13px;
}
.dayIncomeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
.sumIncomeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  background-color: var(--bg-tertiary);
  height: 40px;
}
.incomeCalendarContainer {
  width: calc(100% - 96px);
  margin-left: 96px;
  display: flex;
  flex-direction: column;
}
.incomeCalendarRow {
  display: flex;
  border-top: 1px solid var(--border-color);
  width: 100%;
  height: auto;

  &__first {
    display: flex;
    width: 96px;
    min-width: 96px;
    margin-left: -96px;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    padding-top: 36px;

    &--included {
      font-size: 10px;
      color: var(--brutto-color);
    }
    &--excluded {
      font-size: 10px;
      color: var(--text-secondary);
    }
  }

  &__data {
    display: flex;
    flex: 1;
    width: 100%;
  }

  &__nameWeek {
    width: 96px;
    min-width: 96px;
  }
}

.IncomeHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  &__info {
    display: flex;
    gap: 12px;
    align-items: center;

    & .text {
      color: var(--text-secondary);
      font-size: 12px;
    }
    & .name {
      color: var(--text-primary);
      font-size: 12px;
    }
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  background-color: var(--bg-primary);
}

.incomeSortingContainer {
  display: flex;
  justify-content: center;
  position: relative;

  .leftSection {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    .backButton {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 12px;
      border: none;
      background: none;
      color: var(--text-primary);
      cursor: pointer;
      font-size: 14px;
      
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .centerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}

.scrollableContent {
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.income-page {
  height: 100vh;
  flex-direction: column;

  .mainContainer {
    flex: 1;
    padding: 16px;
    background-color: var(--background-color);
  }

  .tableWrapper {
    height: 100%;
  }
}

.income-tabs {
  display: flex;
  gap: 24px;
  height: 100%;
  align-items: center;

  .tab-button {
    background: none;
    border: none;
    color: var(--text-secondary);
    font-size: 14px;
    padding: 8px 0;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;

    &:hover {
      color: var(--text-primary);
    }

    &.active {
      color: var(--text-primary);
      font-weight: 500;

      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background: var(--primary-color);
        border-radius: 1px;
      }
    }
  }
}

.vat-toggle {
  display: flex;
  align-items: center;
  margin-left: 16px;

  label {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-secondary);
    font-size: 13px;
    cursor: pointer;

    input[type="checkbox"] {
      cursor: pointer;
    }
  }
}

.summary-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px;
  margin-bottom: 24px;
  padding: 16px;
  background: var(--bg-secondary);
  border-radius: 8px;
  width: 100%;
  max-width: 1200px;

  .summary-item {
    justify-self: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;

    .title, .value, .comparison, .year-comparison {
      width: fit-content;
    }

    .title {
      font-size: 14px;
      color: var(--text-secondary);
    }

    .value {
      font-size: 24px;
      font-weight: 500;
      color: var(--text-primary);
    }

    .comparison {
      font-size: 13px;
      color: var(--text-secondary);

      &.positive {
        color: var(--success-color);
      }

      &.negative {
        color: var(--error-color);
      }
    }
  }
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  padding: 16px 150px 0 100px;

  .summary-item {
    width: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .values-container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .year-comparison {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--text-secondary);
        font-size: 14px;

        .year-label {
          min-width: 45px;
          flex-shrink: 0;
        }

        .value {
          flex-shrink: 0;
        }

        .change-indicator {
          flex-shrink: 1;
          white-space: nowrap;
        }
      }
    }
  }
}

.incomeContent {
  padding: 24px 8px;
  max-width: 100%;
}

.incomeContentInner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
