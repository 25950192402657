@use '../abstracts/colors' as c;
@use '../abstracts/variables' as v;

.Toastify__toast {
  width: v.fib(8);
  height: v.fib(6);
  background-color: c.$grey-black;
  border-radius: v.get-radius('md');
}

.success-notification {
  border: v.fib(1) / 8 solid #77be1d;
}

.error-notification {
  border: v.fib(1) / 8 solid #d92828;
}

.Toastify__progress-bar {
  display: none;
}

.Toastify__toast-icon {
  display: none;
} 