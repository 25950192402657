.mainWrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 400px;
  position: relative;
  z-index: 1;
}
.inputsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 280px;
  width: 512px;
  background: rgba(18, 18, 18, 0.8) !important;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 12px;
}
.inputEmailContainer {
  display: flex;
  flex-direction: column;
}
.inputPasswordContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.inputTitle {
  font-size: 13px;
  font-weight: 500;
  color: rgba(250, 250, 250, 0.7);
  margin-bottom: 4px;
}
.inputEmail, .inputPassword {
  margin-top: 8px;
  border: 1px solid rgba(250, 250, 250, 0.1);
  border-radius: 8px;
  color: rgba(250, 250, 250, 0.9);
  height: 44px;
  width: 400px;
  background-color: rgba(18, 18, 18, 0.9) !important;
  padding: 0 16px;
  font-size: 14px !important;
  line-height: 1.2 !important;
  transition: all 0.2s ease;
  
  &:focus {
    outline: none;
    border-color: rgba(250, 250, 250, 0.2);
    background-color: rgba(18, 18, 18, 1) !important;
    box-shadow: none;
  }

  &::placeholder {
    color: rgba(250, 250, 250, 0.3);
    font-size: 14px !important;
  }
}
.buttonLogin {
  width: 188px !important;
  height: 40px !important;
  margin-top: 20px !important;
  background-color: rgba(250, 250, 250, 0.05) !important;
  border-width: 0 !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  text-transform: none !important;
  cursor: pointer;
}
.buttonLogin:hover {
  background-color: rgba(250, 250, 250, 0.2);
}
.error {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 400;
  width: 78%;
}
.eyeIconContainerLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  margin-left: 360px;
  margin-top: 40px;
  color: rgba(250, 250, 250, 0.5);
  padding: 8px;
  
  &:hover {
    color: rgba(250, 250, 250, 0.8);
  }
}
.version-info {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 12px;
  color: rgba(250, 250, 250, 0.4);
  font-weight: 400;
}

input:-webkit-autofill-selected {
  font-size: 14px !important;
}

@-moz-document url-prefix() {
  .inputEmail, .inputPassword {
    font: inherit !important;
    font-size: 14px !important;
  }
}

.loginContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: rgb(18, 18, 18);

  .logo-wrapper {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }

  .login-logo {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.2));
  }
}
