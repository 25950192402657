@use '../../../../../../../styles/abstracts/variables' as v;

.type-selector {
  padding: 24px;
  background: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
  }

  &__button {
    padding: 16px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background: var(--bg-primary);
    color: var(--text-primary);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: var(--bg-secondary);
      border-color: var(--border-hover-color);
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(0);
    }

    &.--active {
      background: var(--bg-active);
      border-color: var(--border-active-color);
      color: var(--text-active);
      font-weight: 500;
    }
  }
} 