@use '../abstracts/colors' as c;
@use '../abstracts/variables' as v;

* {
  scrollbar-width: thin;
  scrollbar-color: var(--text-secondary) var(--bg-primary);

  &::-webkit-scrollbar {
    width: v.fib(2);
  }

  &::-webkit-scrollbar-track {
    background: c.$main-black;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #333333;
    border-radius: v.get-radius('md');
    border: v.fib(1) solid c.$main-black;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

:hover::-webkit-scrollbar {
  width: v.fib(2);
} 