.Tables {
  position: relative;
  width: 100%;
  transition: opacity 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.overlay {
    opacity: 0.3;
    pointer-events: none;
  }

  & > div {
    position: absolute;
    transition: height 0.5s ease-in-out;
    width: auto;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 0;

    & > div {
      width: auto;
      margin: 0 auto;
    }

    &.opacityGray {
      opacity: 0.6;
    }
  }

  &:not(.loading) > div {
    transition: top 1s ease-in-out, transform 0.3s ease-in-out;
  }
}
