@use '../abstracts/variables' as v;
@use '../abstracts/mixins' as m;

// Base table structure
.table-base {
  display: grid;
  width: 100%;
  gap: 0;
  min-height: 0;
  overflow: auto;
}

// Row structure
.table-row {
  display: grid;
  grid-template-columns: 24px var(--column-width) 1fr;
  gap: 0;
  width: 100%;
  min-height: 0;
}

// Data grid for values
.table-data {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: var(--column-width);
  gap: 0;
}

// Cell styles
.table-cell {
  height: var(--cell-height);
  min-height: var(--cell-height);
  width: var(--column-width);
  min-width: var(--column-width);
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--border-color);
  font-size: v.fib(3);
  
  &--left {
    justify-content: flex-start;
    padding-left: v.fib(4);
  }
  
  &--center {
    justify-content: center;
  }

  &--header {
    background: transparent;
  }
} 